<template>
  <div class="procedure">
    <div class="add_product">
      <van-nav-bar
        title="选择工序"
        left-text="返回"
        left-arrow
        fixed
        @click-left="onClickLeft"
      />
    </div>
    <div class="search">
      <van-search
        v-model="name"
        show-action
        placeholder="请输入工序名称"
        @search="onSearch"
      >
        <template #action>
          <div @click="toAddModify" class="icon icon-jjia-"></div>
        </template>
      </van-search>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      error-text="请求失败，点击重新加载"
      :error.sync="error"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="procedure_content">
        <van-checkbox-group v-model="procedureIdList">
          <div
            class="procedure_content_item"
            v-for="item in procedureList"
            :key="item.id"
          >
            <van-checkbox shape="square" :name="item.id"
              >{{ item.wpCode }} | {{ item.wpName }}</van-checkbox
            >
            <div
              class="modify icon icon-xiugai"
              @click.stop="toUpdataModify(item)"
            ></div>
          </div>
        </van-checkbox-group>
      </div>
    </van-list>
    <div class="submit">
      <div class="submit_btn" @click="headleItemChangeDate">确定</div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { ChangePageTitle } from "../../common/js/upTitle";
import { getEwoProcedureList, } from "@/api/procedure.js";
export default {
  computed: {
    ...mapGetters(["departmentCode"]),
  },
  data() {
    return {
      procedureIdList: [],
      name: "",
      procedureList: [],
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 10,
      error: false,
    };
  },
  created() {
    ChangePageTitle("选择工序");
  },
  methods: {
    ...mapActions(["procedure/setNewProcedureList"]),
    onClickLeft() {
      this.$router.push("/craft");
      window.localStorage.removeItem('modifyId')
    },
    //获取工序列表
    async onLoad() {
      try {
        const res = await getEwoProcedureList({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          keyword: null,
          departmentCode: this["departmentCode"],
          wpName: this.name
        });
        this.procedureList.push(...res.records);
        this.loading = false;
        if (this.pageIndex * 10 < res.total) {
          this.pageIndex = res.pageIndex + 1;
        } else {
          this.finished = true;
        }
      } catch (err) {
        // 展示错误提示状态
        this.error = true;
        // 请求失败了，loading 也需要关闭
        this.loading = false;
      }
    },
    async onSearch(v) {
      this.name = v;
      this.procedureList = []
      this.onLoad();
    },
    toAddModify() {
      this.$router.push("/modify");
    },
    toUpdataModify(i) {
      window.localStorage.setItem('modifyId',i.id)
      this.$router.push({
        name: "/modify"
      });
    },
    headleItemChangeDate() {
      var _that = this;
      if (_that.procedureIdList.length == 0) {
        this.$toast("请选择工序");
        return;
      } else {
        var newProcedureList = [];
        for (var i = 0; i < _that.procedureIdList.length + 1; i++) {
          !(function (ii) {
            const index = _that.procedureList.findIndex(
              (v) => v.id == _that.procedureIdList[ii]
            );
            if (index != -1) {
              newProcedureList.push({
                id: _that.procedureIdList[ii],
                wpName: _that.procedureList[index].wpName,
                wpCode: _that.procedureList[index].wpCode,
              });
            }
          })(i);
        }
        console.log(newProcedureList);
        this["procedure/setNewProcedureList"](newProcedureList);
        this.$router.push({
          name: "/craft",
        });
      }
    },
  },
  components: {
    scroll,
  },
};
</script>
<style lang="less" scoped>
.procedure {
  width: 100vw;
  height: 100vh;
  .search {
    width: 100%;
    position: fixed;
    top: 85px;
    left: 0;
    z-index: 100;
    /deep/ .van-search {
      background-color: #f5f7f9;
      .van-search__content {
        background-color: #fff;
      }
      .icon-jjia-{
        color: blue;
        font-size: 40px;
      }
    }
  }
  /deep/ .van-list{
      padding-top: 180px;
      .van-list__finished-text{
          margin-bottom: 150px;
      }
  }
  .procedure_content {
    width: 100vw;
    .procedure_content_item {
      width: 95%;
      margin: auto;
      margin-top: 30px;
      font-size: 30px;
      background-color: #fff;
      box-sizing: border-box;
      padding: 20px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .procedure_content_name {
        display: flex;
        align-items: center;
        .icon {
          font-size: 50px;
          margin-right: 15px;
        }
        .name {
          color: black;
        }
      }
      .modify {
        color: blue;
        font-size: 40px;
      }
    }
  }
  .submit {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 80px;
    font-size: 30px;
    text-align: center;
    line-height: 80px;
    background-color: #fff;
    .submit_btn {
      width: 90%;
      height: 80%;
      margin: auto;
      margin-top: 10px;
      background-color: #4fc08d;
      color: #fff;
      border-radius: 10px;
    }
  }
}
</style>
